/***********************************************/
// Capa de Servicios para el modulo de Medicos.

import axios from "axios";
import store from "@/store/store.js";

export default new (class {
  #baseUrl = null;
  errormsg = "";
  message = null;
  header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    };

  constructor() {
    // Inicializar campos y propiedades.
    this.#baseUrl = store.getters.getBaseURL;
  }

  async reload() {
    this.header = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    };
  }

  async getAllRecords() {
    try {
      let response = await axios.get(this.#baseUrl + "/doctors");
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getData() {
    try {
      let response = await axios.get(this.#baseUrl + "/view/doctors");
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getViewList() {
    try {
      let response = await axios.get(this.#baseUrl + "/view-list/doctors");
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getViewListByNumber(unique=false, venc=false, active=false) {
    try {
      let response = await axios.get(`${this.#baseUrl}/view-list-number/doctors?unique=${unique}&venc=${venc}&active=${active}`);
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getRecord(id) {
    try {
      let response = await axios.get(`${this.#baseUrl}/doctors/${id}`);
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async addRecord(formData) {
    try {
      await this.reload();
      let specialtiesList = formData.specialties;
      let recordData = new FormData();
      recordData.append("id", formData.id);
      recordData.append("name", formData.name);
      if (formData.register_number) {
        recordData.append("register_number", formData.register_number);
      } else {
        recordData.append("register_number", "");
      }
      recordData.append("description", formData.description);
      recordData.append("description_english", formData.description_english);
      recordData.append("address", formData.address);
      recordData.append("email", formData.email);
      recordData.append("phone1", formData.phone1);
      recordData.append("tiktok", formData.tiktok);
      recordData.append("excellence", formData.excellence?1:0);
      recordData.append("premium", formData.premium?1:0);
      recordData.append("repsabi", formData.repsabi?1:0);
      recordData.append("phone2", formData.phone2);
      recordData.append("map_lat", formData.map_lat);
      recordData.append("map_lng", formData.map_lng);
      recordData.append("city_id", formData.city_id);
      recordData.append("web_page", formData.web_page);
      recordData.append("outstanding", formData.outstanding?1:0);
      recordData.append("active", formData.active?1:0);
      if (formData.profesional_license_number) {
        recordData.append("profesional_license_number", formData.profesional_license_number);
      } else {
        recordData.append("profesional_license_number", "");
      }

      if (formData.specialty_license_number) {
        recordData.append("specialty_license_number", formData.specialty_license_number);
      } else {
        recordData.append("specialty_license_number", "");
      }

      if (formData.certification_validity) {
        recordData.append("certification_validity", formData.certification_validity);
      } else {
        recordData.append("certification_validity", "");
      }
      if (formData.certificate_number) {
        recordData.append("certificate_number", formData.certificate_number);
      } else {
        recordData.append("certificate_number", "");
      }

      if (formData.professional_license_verification) {
        recordData.append("professional_license_verification", formData.professional_license_verification);
      } else {
        recordData.append("professional_license_verification", "");
      }
      recordData.append("facebook_profile", formData.facebook_profile);
      recordData.append("twitter_profile", formData.twitter_profile);
      recordData.append("instagram_profile", formData.instagram_profile);
      recordData.append("youtube_video", formData.youtube_video);
      recordData.append("coparmex", formData.coparmex?1:0);
      recordData.append("state", formData.state);
      if (formData.membership) {
        recordData.append("membership", formData.membership);
      } else {
        recordData.append("membership", "0");
      }
      recordData.append("servicehours", formData.servicehours);
      let response = await axios.post(this.#baseUrl + "/doctors", recordData, {
          headers: this.header
        });
      if (response.data.success) {
        let doctor_id = response.data.id;
        let specialtiesIds = [];
        specialtiesList.forEach((item) => {
          specialtiesIds.push(item.id);
        });
        this.registerSpecialties(doctor_id, specialtiesIds);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  // Pushes posts to the server when called.
  async updateRecord(formData) {
    try {
      await this.reload();
      let specialtiesList = formData.specialties;

      let response = await axios.put(
        `${this.#baseUrl}/doctors/${formData.id}`,
        {
          id: formData.id,
          name: formData.name,
          register_number: formData.register_number ? formData.register_number : "",
          description: formData.description,
          description_english: formData.description_english,
          address: formData.address,
          email: formData.email,
          phone1: formData.phone1,
          phone2: formData.phone2,
          map_lat: formData.map_lat,
          map_lng: formData.map_lng,
          city_id: formData.city_id,
          web_page: formData.web_page,
          tiktok: formData.tiktok,
          excellence: formData.excellence,
          premium: formData.premium,
          repsabi: formData.repsabi,
          outstanding: formData.outstanding,
          profesional_license_number: formData.profesional_license_number ? formData.profesional_license_number : "",
          specialty_license_number: formData.specialty_license_number ? formData.specialty_license_number : "",
          certification_validity: formData.certification_validity ? formData.certification_validity : "",
          certificate_number: formData.certificate_number ? formData.certificate_number : "",
          professional_license_verification: formData.professional_license_verification ? formData.professional_license_verification : "",
          facebook_profile: formData.facebook_profile,
          twitter_profile: formData.twitter_profile,
          instagram_profile: formData.instagram_profile,
          youtube_video: formData.youtube_video,
          membership: formData.membership ? formData.membership : "0",
          servicehours: formData.servicehours,
          vencimiento_fecha: formData.vencimiento_fecha,
          coepris_qr_link: formData.coepris_qr_link,
          active: formData.active,
          coparmex: formData.coparmex,
          state: formData.state,
        },
        {
          headers: this.header
        }
      );

      if (response.data.success) {
        let doctor_id = response.data.id;
        let specialtiesIds = [];
        specialtiesList.forEach((item) => {
          specialtiesIds.push(item.id);
        });
        this.registerSpecialties(doctor_id, specialtiesIds);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async deleteRecord(id) {
    try {
      await this.reload();
      let response = await axios.delete(`${this.#baseUrl}/doctors/${id}`, {
          headers: this.header
        });
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async registerSpecialties(doctor_id, specialtiesIds) {
    try {
      await this.reload();
      let formData = new FormData();
      formData.append("doctor_id", doctor_id);
      formData.append("specialties_ids", specialtiesIds);
      let response = await axios.post(
        this.#baseUrl + "/register-specialties/doctors",
        formData,
        {
          headers: this.header
        }
      );
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async deleteSpecialties(doctor_id, specialtiesIds) {
    try {
      await this.reload();
      let formData = new FormData();
      formData.append("doctor_id", doctor_id);
      formData.append("specialties_ids", specialtiesIds);
      let response = await axios.post(
        this.#baseUrl + "/delete-specialties/doctors",
        formData,
        {
          headers: this.header
        }
      );
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async uploadImage(doctor_id, picture_id, imageFile) {
    if (imageFile) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("doctor_id", doctor_id);
        formData.append("picture_id", picture_id);
        formData.append("file", imageFile);
        let response = await axios.post(
          this.#baseUrl + "/upload-image/doctors",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  async deleteImage(doctor_id, picture_id) {
    if (picture_id) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("doctor_id", doctor_id);
        formData.append("picture_id", picture_id);
        let response = await axios.post(
          this.#baseUrl + "/delete-image/doctors",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  //  qr

  async deleteQr(doctor_id, coepris_qr) {
    if (coepris_qr) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("doctor_id", doctor_id);
        formData.append("coepris_qr", coepris_qr);
        let response = await axios.post(
          this.#baseUrl + "/delete-qr/doctors",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  async uploadQr(doctor_id, qr_code_id, imageFile, isQr = true) {
    if (imageFile) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("doctor_id", doctor_id);
        formData.append("qr_code_id", qr_code_id);
        formData.append("file", imageFile);
        formData.append("isQr", isQr);
        let response = await axios.post(
          this.#baseUrl + "/upload-image/doctors",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  ////////////////////////////////////////////////

  async sendEmail(profile_id, email) {
    try {
      let response = await axios.get(
        `${this.#baseUrl}/send-email/doctors?id=${profile_id}&email=${email}`
      );
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      let result;
      result = this.handleError(error);
      return result;
    }
  }

  //////////////////////////////////////////////////////

  async uploadFilePicture(signedUrl, formData, headerspicture) {
    try {
      await this.reload();
      let response = await axios.put(signedUrl, formData, {...headerspicture, 
          headers: this.header
        });
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }

  }


  ////////////////////////////////////////////////

  async sendManyEmail(profile_id, emails) {
    try {
      let response = await axios.get(
        `${this.#baseUrl}/send-manyemail/doctors?id=${profile_id}&emails=${emails}`
      );
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      let result;
      result = this.handleError(error);
      return result;
    }
  }


  handleError(error) {
    let valueError;
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      valueError = error.response.data.messaje;
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      valueError = error.request.data;
    } else {
      // Something happened in setting up the request and triggered an Error
      valueError = error.message;
    }
    return valueError;
  }
})();
